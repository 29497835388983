import React, {Component} from 'react';
import Banner from './Banner';

import {__} from "../../layouts/utilities/i18n";
import {getQueryArgs, getQueryName, queryCollection, querySingle} from "../../layouts/schema";
import {Query, withApollo} from "react-apollo";
import Loading from "../../layouts/utilities/Loading";


class Banners extends Component {

	shuffle(a) {
		var j, x, i;
		for (i = a.length - 1; i > 0; i--) {
			j = Math.floor(Math.random() * (i + 1));
			x = a[i];
			a[i] = a[j];
			a[j] = x;
		}
		return a;
	}

	bannerList =  collection => 
	{
		const _banners = collection.map ((element,index) =>  
		{
			return <Banner {...element} key={index} isVertical={this.props.isVertical} />
		});
		return <div className="container">
			<div className="row banners justify-content-center">
				{_banners}
			</div>
		</div>
	}

	render () {
		const query_name = getQueryName("Banner");
		const query_args = getQueryArgs("Banner");
		const query = queryCollection( "Banner", query_name, query_args );

		return <Query query={query}>
			{
				({ loading, error, data, client}) =>
				{
					if( loading)
					{
						return <Loading/>;
					}
					if(data)
					{
						let collection = data[query_name] ? data[query_name] : [] ;						
						const count = this.props.count ? this.props.count : 4;						
						collection = this.shuffle(collection);
						collection = collection.slice(0, count);
						return this.bannerList(collection);
					}
					if(error)
					{
						return error.toString();
					}
				}
			}
		</Query>;
	}
}

export default Banners