import React, {Component} from "react";
import {__} from "../../utilities/i18n"
import ScalarField from "./ScalarField";
import { Tag, ButtonGroup, Button, Intent } from "@blueprintjs/core";

//  Scalar  String

export default class String extends ScalarField
{
	
}