import React, {Component} from 'react';
import BasicState from "../layouts/BasicState";
import {__} from "../layouts/utilities/i18n";
import ContactForm from "./ContactForm";
import {compose} from "recompose";
import {withApollo} from "react-apollo";
import {withRouter} from "react-router";

class SCHelpState extends Component { 
	render()
	{
		return <div className="layout-state">
			<div className="container">
				<div className="row justify-content-center">
					<div className="col-md-7 my-4">
						<img src="/assets/img/sc/logo.png" className="m-4" />
						<p>
							Каталог  альтернативного образования - важная часть большого проекта Альтернативное образование в России. Наш проект был основан 2015 году. Его автор  Алексей Семёнычев. Ссылки на все наши социальные сети можно нажать на первой странице сайта.
						</p>

						<p>
							Мы проводим конференция, фестивали, вебинары, онлайн-конференции по альтернативному, семейному образованию. <a href="https://homeschoolingresurs.ru/" className="text-primary">Наш сайт</a>.
						</p>

						<p>
							Если вы хотите предложить нам сотрудничество, разместить у нас рекламу, написать про накладки на сайте, то это как раз на этой странице в форме, что находится ниже.
						</p>

						<p>
							Сейчас сайт дорабатывается. И он точно будет улучшаться. Сейчас, чтобы внести изменения на странице вашего проекта, написать тоже нужно  нам, и мы внесём, так как вы скажите. Это тоже можно сделать через форму на этой странице.
						</p>

						<ContactForm
							formClass="mb-5"
							title={__("Остались вопросы?")}
						/>
					</div>
				</div>
			</div>
		</div>
	}
}

export default compose(
	withApollo,
	withRouter
)(SCHelpState)
