import React, {Fragment} from 'react';
import LayoutsProvider from "../layouts/LayoutsProvider";
import LayoutAccessLoading from "./LayoutAccessLoading";

class LayoutApp extends React.Component
{
    render() {
        return (
            <LayoutsProvider>
                <LayoutAccessLoading/>
            </LayoutsProvider>
        );
    }
}

export default LayoutApp;