import React, {Component} from 'react';
import {__} from "../../layouts/utilities/i18n";
import SCMultiSelect from "./SCMultiSelect";
import SCSelect from "./SCSelect";
import CategoryCheckboxes from "./CategoryCheckboxes";
import Select from 'react-select'; //https://github.com/JedWatson/react-select
import {Button, ButtonGroup, Dialog, Intent, Callout} from "@blueprintjs/core";
import {withRouter} from "react-router";

class SearchPanel extends Component
{
	state = {
		selected:[],
		area:null,
		search:"",
		coord: this.props.searchAdress || ""
	};

	componentWillReceiveProps ( nextProps )
	{
		if( nextProps.searchAdress != this.state.searchAdress )
		{
			this.setState({ coord: nextProps.searchAdress });
		}		
	}
	render () 
	{
		var cities = this.props.cities.map(e =>
		{
			return {value:e._id, label:e.title};
		});
		var distinatons = this.props.distinatons.map(e =>
		{
			return {value:e._id, label:e.title};
		});
		
		const schbtn = 
			this.state.coord == "" &&  
			this.state.area == null &&  
			this.state.search=="" &&   
			this.state.selected.length == 0
			?
			"btn flex-grow-1 searchbtn  mb-0"
			:
			"btn flex-grow-1 searchbtn selected mb-0";
		const all_cities = [
			
		]

		const _search_count = this.props.isResult ?
			<p className="m-0">Найдено {this.props.count} организаций</p>
			:
			<p className="m-0">В каталоге {this.props.count} организаций</p>;

		return <div className="jumbotron d-flex justify-content-end px-3 pb-4 mb-2">
			<div className="container-lg d-flex flex-column px-0">
				<h1>Каталог альтернативного образования</h1>
				<div className="main-description">
					Главная миссия нашего проекта - развитие альтернативного образования. Наша задача помочь людям найти друг друга. Школам найти учеников. Родителям - школы для своих детей.
					Наши идеи - идеи свободного и гуманного образования. Если вы разделяете их, нам по дороге. Мы рады вам! Мы идем вместе!
				</div>
				<div className="form-row col-lg  px-0 mx-0">
					{/*
					<div className="form-group px-0 mb-0 flex-grow-4">
						<input 
							type="text" 
							className="form-control" 
							placeholder="Какую школу вы хотите найти?" 
							id="inputSchool"
							onChange={this.onSearchChange}
							value={this.state.search}
						/>
					</div>
					*/}
					{/*coord - string*/}
					<div className="form-group px-0 mb-0 flex-grow-4">
						<input 
							id="suggest1"
							type="text" 
							className="form-control" 
							placeholder="Где искать будем?"
							onChange={this.onCoordChange}
							value={this.state.coord}
						/>
					</div>
					<div className={schbtn} onClick={evt => this.props.onSearch(evt)}>
						<span className="font-weight-bold pr-3"> Найти </span> 
						<img className="icon-magnifying-glass " src="/assets/img/sc/icon-magnifying-glass.svg"></img> 
						<div className="icon-magnifying-glass" > </div>
					</div>
				</div>
				<div className="py-4">
					<CategoryCheckboxes 
						categories={this.props.categories}
						selected={this.state.selected}
						onCheck={this.onCheck}
					/>
				</div>
				<div className="row-lg d-flex justify-content-between">
					{_search_count}
				</div>
			</div>
		</div>
	}


	onSearch = (evt)=>{
		this.props.onSearch(evt);
	}

	onChangeCities = newValue =>
	{
		const sel = newValue || [];
		console.log( sel );
		this.setState({ cities: sel });
		this.props.onChangeSearchParam("cities", sel);
		
	}
	onChangeCats = newValue =>
	{
		const sel = newValue || [];
		console.log( sel );
		this.setState({ area: sel });
		this.props.onChangeSearchParam("area", sel);
		
	}
	onCheck = selected =>
	{
		this.setState({ selected });
		this.props.onChangeSearchParam("categories", selected);
	}
	onSearchChange = evt =>
	{
		this.setState({search:evt.currentTarget.value})
		this.props.onChangeSearchParam("search", evt.currentTarget.value);
	}
	onCoordChange = evt =>
	{
		console.log(evt.currentTarget.value)
		this.setState({coord:evt.currentTarget.value})
	}
	
}

export default withRouter(SearchPanel);