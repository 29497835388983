import React, {Component, Fragment} from "react";
import { 
	Icon, Tag, 
	Intent, Tooltip, 
	Card, FormGroup, 
	Button, ButtonGroup,
	Position, Popover, 
	InputGroup,
	Dialog
 } from "@blueprintjs/core";
import {__} from "../layouts/utilities/i18n";
import $ from "jquery";

class SCSchoolMailingAdmin extends Component
{
	constructor(props)
	{
		super(props);
		this.state = {
			value:false
		}
	}
	componentDidMount()
	{
		
	}
	render()
	{
		//console.log(this);
		const {value} = this.state;
		const col1 = this.props.props.vertical ? "col-12 layout-label-vert my-2" : "col-md-3  layout-label my-2";
		const col2 = this.props.props.vertical ? "col-12 layout-data-vert my-2" : "col-md-9 layout-data my-2";
		return this.props.props.isNew && this.props.props.data_type == "Mailing" 
			?
			<div className="row dat" row_data="for-all">
				<div className={col1}>
					{__( "Для всех" )}
				</div>
				<div className={col2} style={{ position : "relative" }}>
					<input 
						type="checkbox"
						className="checkbox "
						id={ "for-all" }
						onChange={ this.onChange }
						value={ value }
						checked={ value == true } 
					/>
					<label htmlFor="for-all" >
						{ __(value ? "Yes" : "No") }
					</label>
				</div>
			</div>
			:
			null;
	}
	onChange = evt =>
	{
		this.setState(
			{value:!this.state.value},
			() => {
				this.state.value 
					?
					$("[data-type='Mailing'][is-new='1'] [row_data='users']").hide()
					:
					$("[data-type='Mailing'][is-new='1'] [row_data='users']").fadeIn()
				this.props.on( this.state.value, "for_all" );
			}
		);
	}
}
export default SCSchoolMailingAdmin;