import React, {Component, Fragment} from 'react';
import CatalogResult from "./CatalogResult";

class CatalogList extends Component
{
	constructor(props)
	{
		super(props);
		this.state =
		{
			offset:this.props.offset, 
			count:this.props.count,
			items: this.props.items
		}
	}
	shouldComponentUpdate(nextProps, nextState)
	{
		return (nextProps.items != this.state.items) || (nextProps.offset != this.state.offset)
	}
	componentWillUpdate(nextProps, nextState)
	{
		this.setState({
			count: nextProps.count,
			offset: nextProps.offset,
			items: nextProps.items
		});
	}
	
	render () 
	{
		//console.log( this.props );
		const items = this.state.items.map ( (element, index) =>
		{
			const start = this.state.offset * this.state.count;
			const finish = this.state.offset * this.state.count + this.state.count;
			return index < finish && index >= start
				? 
				<CatalogResult
					{...element } 
					key={index}
					onShowMap={this.props.onShowMap}
					getPlaceDialog={this.props.getPlaceDialog}
				/> 
				: 
				null;
		});

		return <div className="container-lg px-0  mb-2 ">
			{items}
		</div>;
	}
}

export default CatalogList