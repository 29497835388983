import React, {Component, Fragment} from 'react';
import SearchPanel from './searchPanel/SearchPanel';
import Banners from './Banners';
import {__} from "../layouts/utilities/i18n";
import {sprintf} from "../layouts/utilities/sprintf";
import Loading from "../layouts/utilities/Loading";
import {compose, graphql, withApollo} from "react-apollo";
import {withRouter} from "react-router";
import BasicState from "../layouts/BasicState";
import {Button, Popover, Position, Dialog, Intent, Callout } from "@blueprintjs/core";
import $ from "jquery";

import customsource from './SCMainState/customsource';

import {
	getChangeName,
	getInputTypeName,
	getMutationArgs,
	getQueryArgs,
	getQueryName, mutationEdit,
	queryCollection,
	queryCollectionFilter
} from "../layouts/schema";
import Places from "./mapState/Places";
import "./mapState/style.css";

const query_name =  getQueryName("PublicPlace");
const query_args = getQueryArgs("PublicPlace")
const getPlaces = queryCollectionFilter( "PublicPlace", query_name, query_args, true );
const mutation_name = getChangeName( "PublicPlace" );
const input_type_name = getInputTypeName( "PublicPlace" );
const mutation_args = getMutationArgs( "PublicPlace" );
const changePlace = mutationEdit( "PublicPlace", mutation_name, input_type_name, mutation_args );

const query_name2 = getQueryName("PlaceType")
const query_args2 = getQueryArgs("PlaceType")
const getPlaceTypes = queryCollection( "PlaceType", query_name2, query_args2 );


class SCGeneralPage extends BasicState 
{
	basic_state_data()
	{
		return {
			count:3,
			offset:0,
			position:null,
			isResult: false
		};
	}

	render()
	{
		if( this.props.loadingPlaces
			|| this.props.loadingTeams
			// || this.props.loadingEvents
			|| this.props.loadingPlaceTypes )
			return <div className="layout-state p-0">
				<Loading />
			</div>;
		const placeTypes 	= this.props.placeTypes || [];
		const places 		= this.props.places || [];
		const cities 		= customsource.cities || [];
		const countries 	= customsource.countries || [];
		const distinatons 	= customsource.distinatons || [];
		return <Fragment>
		<div className="layout-state p-0">
			<SearchPanel
				count={ places.length} 
				searchAdress={this.state.searchAdress}
				onSearch={this.onSearch}
				onChangeSearchParam={this.onChangeSearchParam}
				cities={cities}
				countries={countries}
				distinatons={distinatons}
				categories={placeTypes}
				isResult={this.state.isResult}
			/>
			<div className="container">
				<div className="row">
					<div className="col-12">
						<Places places={places} placeTypes={placeTypes} onChangeSearchParam={this.onChangeSearchParam}/>
					</div>
				</div>
			</div>
		</div>
	</Fragment>
	}

	onChangeSearchParam = (name, value) =>
	{
		let state = {};
		state[name] = value;
		this.setState(state);
	}
	onSearch = () =>
	{


		let variables= {paging: {
			
		}};

		if(this.state.count){
			// variables.paging.limit = this.state.count;
		}
		if (this.state.offset){
			// variables.paging.limit = this.state.count;
		}


		if(this.state.geo || this.state.categories){
			console.log("geo");
			console.log(this.state.geo);
			variables.paging.metas = []
			if(this.state.geo){
				variables.paging.metas.push({
					key: "geo",
					value_geo: this.state.geo
				})
			}
			console.log("categories");
			console.log(this.state.categories);
			if(this.state.categories && this.state.categories.length >0){
				variables.paging.metas.push({
					key: "types_ids",
					// value: this.state.categories[0]
					values: this.state.categories
				})
			}
		}

		// {
		// 	bounds: this.state.bounds,
		// 	geo: this.state.geo,
		// 	area: this.state.area,
		// 	search: this.state.search,
		// 	categories: this.state.categories,
		// 	searchAdress: this.state.searchAdress,
		// 	count: this.state.count,
		// 	offset: this.state.offset
		// }

		this.props.fetchMorePlaces({
			variables: variables,
			updateQuery: (prev, { fetchMoreResult }) => 
			{
				//const link = this.searchLink(variables);	
				console.log(fetchMoreResult);
				//this.props.history.replace( link );

				if (!fetchMoreResult) return prev;

				this.setState({
					isResult: true
				})

				return Object.assign({}, prev, {
					getPublicPlaces: fetchMoreResult.getPublicPlaces
				});
			}
		});
	}
	searchLink (variables)
	{
		console.log(variables.paging.metas);
		let links = [];
		links = variables.paging.metas.map((e, i) =>
		{
			if( e.key== "geo" )
			{
				return e.key + "=" + e.value_geo;
			}
			else
			{
				return e.key + "=" + e.value;
			}
			
		});
		return "?" + links.join("&");
	}
}

export default compose(
	graphql(getPlaces,
		{
			options: ( props ) => ({
				variables: { },
				name: "getPublicPlaces"
			}),
			props: ( p ) => {
				const data = p.data;
				//console.log("getPlaces", p);
				return {loadingPlaces: data.loading, places: data.getPublicPlaces, fetchMorePlaces: data.fetchMore}},
		}),
	graphql(getPlaceTypes, {
		options: ( props ) => ({
			variables: { },
			name: "getPlaceTypes"
		}),
		props: ( p ) => {
			const data = p.data;
			//console.log("getPlaceTypes", p);
			return {loadingPlaceTypes: data.loading, placeTypes: data.getPlaceTypes}
		},
	}),
	graphql(changePlace, {"name": "changePublicPlace"}),
	withApollo,
	withRouter
)(SCGeneralPage);