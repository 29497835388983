import React, {Component} from 'react';

import {upload_url} from "../../layouts/config"

class Banner extends Component
 {
	render () 
	{
		//console.log(this.props);
		const _title = this.props.title;
		const _link = this.props.link;
		const default_icon = "/assets/img/sc/image-third-banner.jpg";
		const rowClasses = this.props.isVertical
			?
			"col-12"
			:
			"col-xl-3 col-md-6 col-12";
		const _img = this.props.file ? "url(" +upload_url() +  this.props.file + ")" : "url(" + default_icon + ")";
		
		return <div className={"banner  px-3 py-3 d-flex " + rowClasses}>
			<div className="banner-img" style={{backgroundImage:_img}} title={_title}>
				<a className="stretched-link" href={_link} target="_blank">
				</a>
			</div>
		</div>
}
}

export default Banner