import React from 'react';
import ReactGA from 'react-ga';
import ReactDOM from 'react-dom';

import LayoutApp from './LayoutApp';
// import {google_analytics} from "./layouts/config";

// ReactGA.initialize(google_analytics());
// ReactGA.pageview(window.location.pathname + window.location.search);
ReactDOM.render(
    <LayoutApp />,
    document.getElementById('root')
);

