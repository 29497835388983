import React from "react";

const widgets = {};
function importAll (r) 
{
	// console.log(r)
	r.keys().forEach(key => 
	{
		const key1 = key.replace("./", "").split(".").slice(0, -1).join(".");
		widgets[key1] = r(key)
	});
}
importAll(require.context('../../widgets/', false, /\.js$/));
export default widgets;
