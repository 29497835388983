import React, {Component, Fragment} from "react";
import LayoutMenuLeft from "./LayoutMenuLeft";
import LayoutMain from "./LayoutMain";
import LayoutMenuMain from "./LayoutMenuMain";
import {isMenuLeft} from "../../layouts/template";

class LayoutContent extends Component
{
	constructor(props)
	{
		super(props);
		this.state = {
			current:this.props.current,
			isLeft: isMenuLeft()
		}
	}


	componentWillReceiveProps (nextProps )
	{
		if(nextProps.current !== this.state.current )
			this.setState({
				current: nextProps.current
			});
	}
	render()
	{
		const menu_left = this.state.isLeft
			?
			<LayoutMenuLeft current={this.state.current} onCurrent={this.props.onCurrent} user={this.props.user}/>
			:
			null;
		return <div className="layout-content">
			{menu_left}
			<LayoutMenuMain current={this.state.current} onCurrent={this.props.onCurrent} user={this.props.user}/>
			<LayoutMain current={this.state.current} onChangeStyle={ this.onChangeStyle  } user={this.props.user}/>
		</div>
	}
	//			<div className="alert alert-danger col-12 text-center lead" role="alert">
	// 				<div className="w-100 mb-4"><i className="fas fa-paw fa-3x"/></div>
	// 				{i18n.t("Only logged User can see this.content. Login or register and login please.")}
	// 			</div>

	onChangeStyle = style =>
	{
		if( 
			typeof style.isLeft != "undefined" 
			&& style.isLeft !== this.state.isLeft 
		)
		{
			//console.log(style.isLeft)
			this.setState({isLeft:style.isLeft});
		}
		this.props.onChangeStyle( style );
	}
}
export default LayoutContent;