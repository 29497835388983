import React, {Component, Fragment} from "react";


import {compose} from "recompose";
import { Query, withApollo, graphql } from "react-apollo";
import {withRouter} from "react-router";

import getWidget, { initArea } from "../../layouts/utilities/getWidget";


class PlaceTabs extends Component
{
	state = { 
		navbarTabId: "PlaceTab", 
		mergedPlaceTitle: "", 
		isMergeOpen: false, 
		isTeamLoading : false,
		...this.props, 
		oldTeams: 
			Array.isArray(this.props.teams) 
				? 
				this.props.teams 
				: 
				[]
	}
	render()
	{
		console.log(this.props);
		return this.placeTab();
	}
	placeTab()
	{
		//console.log(this.props);
		const descr = this.props.description ? <div className='map-descr my-3'>{this.props.description}</div> : "";
		const address = <div className='place-descr'>{this.props.address}</div>;
		
		return <div className="p-tab" >
			{ 
				initArea( 
					"before-place-tab", 
					this.props,
					<Fragment>
						<div className="lead ">
							{this.props.title}
						</div>
						{address}
						{descr}
					</Fragment> 
				) 
			}
		</div>;
	}
}
export default compose(
	withApollo,
	withRouter
)(PlaceTabs);