import React, {Component} from "react";
import CatalogResult from "../states/mapState/CatalogResult";

class SCPlaceTab extends Component
{
	render()
	{
		
		return <CatalogResult {...this.props} onShowMap={this.props.onShowMap}/>
	}
}
export default SCPlaceTab;