import React, {Component, Fragment} from "react";
import ReactDOM from 'react-dom';
import $ from "jquery";

class SCMultiSelect extends Component
{
	constructor(props)
	{
		super(props);
		const selected = typeof this.props.selected == "object" && this.props.selected.length ? this.props.selected : [];
		this.state = {
			label: this.getLabel( selected ),
			isOpen: this.props.isOpen,
			selected: selected,
			height: 0
		}
	}
	componentDidMount() 
	{
		document.body.addEventListener('click', this.onMouseLeaveHandler);
	}
    componentWillUnmount() 
	{
		document.body.removeEventListener('click', this.onMouseLeaveHandler);
	}
	onMouseLeaveHandler = e =>
	{	
		const domNode = ReactDOM.findDOMNode(this);
		if (!domNode || !domNode.contains(e.target))	
		{
			this.setState({
				isOpen: false, 
				height: 0
			});
		}
	}
	
	render()
	{
		const options = this.props.options || [];
		const _options = options.map((e, i)=>
		{
			const className = this.state.selected.filter(ee => ee == e.value).length>0 
				? 
				"sc-select-option selected" 
				: 
				"sc-select-option";
			return <div 
				className={className} 
				key={i} 
				value={e.value} 
				onClick={this.onSelect}
			>
				{ e.label }
			</div>
		});
		const className = this.state.isOpen ? "sc-select-data open" : "sc-select-data";
		return <div className="sc-select" id={this.props.id}>
			<div className="sc-select-head" onClick={this.onToggled} >
				{this.state.label}
			</div>
			<div className={className} style={{ height: this.state.height }} >
				{_options}
			</div>
		</div>
	}
	onSelect = evt =>
	{
		const value = evt.currentTarget.getAttribute("value");
		let selected = [...this.state.selected].filter(e => e != value)
		if(!$(evt.currentTarget).hasClass("selected")) selected.push(value);
		console.log( selected );
		this.setState(
			{selected, label: this.getLabel(selected)},
			this.props.onChange(selected)
		);
	}
	onToggled = evt =>
	{
		this.setState({isOpen : !this.state.isOpen, height : this.getHeight() });
	}
	getHeight = () =>
	{
		if( !this.state.isOpen )
		{
			let total = 2;
			$("#"+this.props.id + " .sc-select-data")
				.children()
					.each((i, e) =>
					{
						total += $(e).outerHeight();
					});
			return total;
		}
		else
		{
			return 0;
		}
	}
	getLabel = selected =>
	{
		const options = this.props.options || [];
		let label = "";//this.props.placeholder;
		if(this.state)
		{
			options.forEach(e => {
				if( selected.filter(ee => ee == e.value).length > 0 )
					label += " " + e.label;
			});
			return label ? label : this.props.placeholder;
		}
		else
		{
			options.forEach(e => {
				if( selected.filter(ee => ee == e.value).length > 0 )
					label += " " + e.label;
			});
			return label ? label : this.props.placeholder;
		}
	}
}
export default SCMultiSelect;