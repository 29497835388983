import React from "react";
import getWidget, { initArea } from "../../layouts/utilities/getWidget";

class LayoutFooter extends React.Component {

	render()
	{
		return <footer >
			{ initArea( "layout-footer", { a:1 } ) }
            </footer>
    }
}

export default LayoutFooter;

//https://github.com/apollographql/react-apollo/issues/660
