import React, {Component, Fragment} from "react";
import {Button, Intent } from "@blueprintjs/core";
import OwlCarousel from 'react-owl-carousel2';
import 'react-owl-carousel2/src/owl.carousel.css'; //Allows for server-side rendering.

class CategoryCheckboxes extends Component
{
	state =
	{
		selected:this.props.selected || []
	}
	componentWillReceiveProps ( nextProps )
	{
		if( nextProps.selected != this.state.selected )
		{
			this.setState({ selected: nextProps.selected });
		}		
	}
	componentDidMount ()
	{
		this.updateWindowDimensions();
		window.addEventListener('resize', this.updateWindowDimensions);
	}
	componentWillUnmount() 
	{
		window.removeEventListener('resize', this.updateWindowDimensions);
	}
	updateWindowDimensions = () =>
	{
		
		this.setState({ 
			width : document.body.clientWidth, 
			height: document.body.clientHeight			
		});

	}
	
	
	render()
	{
		return this.state.width > 900 ? this.screen() : this.mobile();
	}
	screen()
	{
		var categories = this.props.categories.map((e, i) =>
		{
			const className = this.state.selected.filter(ee => e._id == ee ).length > 0 
				?
				"cat-filter selected" 
				:
				"cat-filter";
			const style = this.state.selected.filter(ee => e._id == ee ).length > 0 
				?
				{ backgroundColor:e.color }
				:
				null
			return <div key={i} className="d-flex ">
				<div 
					className={className}
					style={style}
					onClick={ () => this.onClick(e._id) } 
				>
					{e.title}
				</div>
			</div>
		});
		return <div className="d-flex flex-wrap"> 
			{ categories}
		</div>;
	}
	mobile( )
	{
		const options = { 
			center: true,
			loop: true, 
			drag:false,
			autoWidth:true,
			navText:["  ", "  "],
			rtl:true,
			nav:true,
			margin:2 , 
			items:4,
			responsive:{
				0:{
					items:1
				},
				1000:{
					items:4
				},
				1200:{
					items:5
				}
			}
		};
		const coursesList = this.props.categories.map((e, i) =>
		{
			const className = this.state.selected.filter(ee => e._id == ee ).length > 0 
				?
				"cat-filter selected" 
				:
				"cat-filter";
			const style = this.state.selected.filter(ee => e._id == ee ).length > 0 
				?
				{ backgroundColor:e.color }
				:
				null
			return <div className="courses-slider__item" key={i}>
				<div key={i} className="d-flex ">
					<div 
						className={className}
						style={style}
						onClick={ () => this.onClick(e._id) } 
					>
						{e.title}
					</div>
				</div>
			</div>
		});
		return <OwlCarousel options={options}>
			{coursesList}
		</OwlCarousel>
	}
	
	onClick = _id =>
	{
		let selected = [ ...this.state.selected ];
		if( selected.filter(ee => _id == ee ).length > 0 )
		{
			selected = selected.filter(ee => _id != ee);
		}
		else
		{
			selected.push(_id);
		}
		this.props.onCheck(selected); 
	}
}
export default CategoryCheckboxes;