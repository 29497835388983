import React, {Fragment, Component} from "react";
import DataTableElements from "./DataTableElements";
import Row from "./Row";

import $ from "jquery";
import CategoryForm from "./CategoryForm";


import { 
	Icon, Tag, Classes,
	PopoverInteractionKind, PopoverPosition,
	Intent, Tooltip, 
	Card, FormGroup, 
	Button, ButtonGroup,
	Position, Popover, Callout,
	InputGroup 
 } from "@blueprintjs/core";

import { compose, mapProps } from "recompose";
import {Query, withApollo} from 'react-apollo';
import {withRouter} from "react-router";

import {AppToaster} from "../../layouts/utilities/blueUtils";
import {__} from "../../layouts/utilities/i18n";
import {sprintf} from "../../layouts/utilities/sprintf";
import {onSaveGql} from "../../layouts/schema/ecosystem";
import Loading from "../../layouts/utilities/Loading";

class DataTable extends Component
{
	constructor(props)
	{
		super(props);
		//console.log( this.props );
		// const post = this.props.data ? this.props.data : [];
		let checked= {}, current = -1;
		// post.forEach(e => checked[e._id] = 0);
		// if(this.props.match.params.id)
		// {
		// 	for( var e in post)
		// 	{
		// 		if(post[e]._id == this.props.match.params.id)
		// 		{
		// 			current = e;
		// 			break;
		// 		}
		// 	}
		// }
		this.state = {
			current 	: current,
			checked 	: checked,
			height		: 260,
			isNew 		: false,
			// post 		: post,
			theadColor 	: this.props.theadColor || "#87A629" ,
			trColor 	: this.props.trColor || "#52631e" ,
			isOpen1		: false,
			isOpen2		: false,
			isOpen3		: false,
		};
		// this.init({
		// 	form_component	: CategoryForm,
		// 	meta			: {color: "#FFF"}
		// })
	}
	render()
	{

		//console.log(this.props.query);
		return <Query query={this.props.query}>
			{
				({ loading, error, data, client}) =>
				{
					//console.log(data);
					if( loading)
					{
						return <Loading/>;
					}
					if(data)
					{
						const collection = data[this.props.query_name];

						return collection.length > 0
							?
							this.props.isList
								?
								this.do_list(collection)
								:
								this.do_table(collection)
							:
							this.do_table(collection)
					}
					if(error)
					{
						return error.toString();
					}
				}
			}
		</Query>;

	}

	// <Callout
	// 	icon=""
	// 	intent={Intent.PRIMARY}
	// 	className="p-4"
	// 	title={ __(this.props.data_type) }
	// >
	// 	{__("No one elements evalbled.")}
	// </Callout>
	do_list(data)
	{
		console.log(this.props, data);
		const isListNew = this.props.isListNew
			?
			<table className="table mb-5">
				<thead>
					<Row
						i={-2}
						count={data.length}
						trColor={this.state.theadColor}
						data_type={this.props.data_type}
						query_name = {this.props.query_name}
						query = {this.props.query}
						mutation_name={this.props.mutation_name}
						change_mutation={this.props.change_mutation}
						mutation_delete={this.props.mutation_delete}
						isOpen={ this.state.current == -2 }
						onOpen={this.onCurrent}
						route={this.props.route}
						onAllChecked={this.onAllChecked}
						elem={{}}
						isNew={true}
						allChecked={ this.state.allChecked }
					/>
					{ this.addThead() }
				
				</thead>
			</table>
			:
			null;
		let rows = data.map((e,i) =>
		{
			return <div className="row data-list input " key={i}>
				<div className="col col-md-12">
					<CategoryForm 
						{...e}
						editable={false}
						ID={e._id }							
						data={e}	
						data_type={this.props.data_type}
						isOpen={ true } // for Yandex Maps
						isHiddenSave={true}
						isHiddenClose={true}
						isHiddenDelete={true}
					/>
				</div>
			</div>
		});
		//if(is_new_list)
		return <section>
			{isListNew}
			{rows}
		</section>
	}
	do_table(data)
	{				
		let bb = false;
		for (var prop in this.state.checked)
			bb = bb || this.state.checked[prop];
		return <Fragment>
			<div className="row pb-2">
				<div className={ "col-md-12 " + ( bb ? "" : "unvisibled" ) } >	
					<ButtonGroup minimal={false} >
						<Popover 
							popoverClassName={ Classes.POPOVER_CONTENT_SIZING } 
							interactionKind ={ PopoverInteractionKind.CLICK }
							position={ PopoverPosition.RIGHT }
							content ={
								<Button text={__("Delete")} onClick={this.onActionDelete} />
							}
							
						>
							<Button intent={Intent.DANGER}text={__("Delete")} className="px-3" icon="trash" />
						</Popover>
						<Popover 
							popoverClassName={ Classes.POPOVER_CONTENT_SIZING } 
							interactionKind ={ PopoverInteractionKind.CLICK }
							position={ PopoverPosition.RIGHT }
							content ={
								<Button text={__("Publish")} onClick={this.onActionShow} />
							}
							
						>
							<Button intent={Intent.PRIMARY} text={__("Publish")} className="px-4 " icon="eye-open"/>
						</Popover>
						<Popover 
							popoverClassName={ Classes.POPOVER_CONTENT_SIZING } 
							interactionKind ={ PopoverInteractionKind.CLICK }
							position={ PopoverPosition.RIGHT }
							content ={
								<Button text={__("Hide")} onClick={this.onActionHide} />
							}
							
						>
							<Button intent={Intent.WARNING} text={__("Hide")} className="px-4 " icon="eye-off"/>
						</Popover>
					</ButtonGroup>
				</div>
			</div>
			<div style={{width: "100%", overflow: "auto"}}>
			<table className="table mb-5">
				<thead>
					<Row
						i={-2}
						count={data.length}
						trColor={this.state.theadColor}
						data_type={this.props.data_type}
						query_name = {this.props.query_name}
						query = {this.props.query}
						mutation_name={this.props.mutation_name}
						change_mutation={this.props.change_mutation}
						mutation_delete={this.props.mutation_delete}
						isOpen={ this.state.current == -2 }
						onOpen={this.onCurrent}
						route={this.props.route}
						onAllChecked={this.onAllChecked}
						elem={{}}
						isNew={true}
						allChecked={ this.state.allChecked }
					/>
					{ this.addThead() }
				
				</thead>
				<DataTableElements 
					count={data.length}
					posts={data}
					current={this.state.current}
					trColor={this.state.trColor}
					height={this.state.height}
					data_type={this.props.data_type}
					query_name = {this.props.query_name}
					query = {this.props.query}
					mutation_name={this.props.mutation_name}
					change_mutation={this.props.change_mutation}
					mutation_delete={this.props.mutation_delete}
					route={this.props.route}
					onCurrent={this.onCurrent}
					isNew={false}
					checked={this.state.checked}
					showAllChecked={this.showAllChecked}
				/>
			</table>
			</div>
		</Fragment>
	}
	showAllChecked = ( checked ) =>
	{
		let a = 1;
		for(let i in checked)
		{
			a = a * checked[i];
		}
		this.setState({ checked, allChecked: a });
	}
	onAllChecked = allChecked =>
	{
		let checked = {};
		this.state.post.forEach(e => checked[e._id] = allChecked ? 1 : 0 );	
		this.setState({ allChecked, checked });
	}
	onOpenNew = () => this.setState({ current: -2 });
	onCurrent = current => 
	{
		const { route, history, match} = this.props;
		this.setState({ current: current });
		//console.log( current  );
		let path;
		switch(current)
		{
			case -1:
				path = route;
				break;
			case -2:
				path = route + "/new";
				break;
			default:
				path = route + "/" + this.state.post[current]._id;
				break;
		}
		history.push( path );
	}
	addThead()
	{
		return null;
	}
	onActionDelete = () =>
	{
		const l = Object.keys(this.state.checked);		
		if( l.length < 1 )
		{
			AppToaster.show({  
				intent: Intent.DANGER,
				icon: "tick", 
				message: "Select one or nmore elements. " 
			});
			return;
		}		
	}
	onActionHide = ()=>{

	}

	onSave = (state, _id) =>
	{
		onSaveGql(state, _id);

	}
	onDelete = _id =>
	{
		console.log(_id);
	}
	
	/*
	//	override methods
	*/
	
	
	init(data)
	{
		this.form_component 	= data.form_component;
		this.meta		 		= data.meta;
		this.theadColor			= data.theadColor || "#2d74ab";
		//this.setState({ theadColor: this.theadColor	});
	}
	
}
		
export default compose(
	withApollo,
	withRouter
)(DataTable);