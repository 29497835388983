import React, {Component, Fragment} from "react";
import {compose} from "recompose";
import {Router, Route, Switch, Redirect, withRouter} from 'react-router';
import {concatRouting, mainPage, routeData} from "../../layouts/routing"
import {isCapability} from "../../layouts/user";
import UserContext from "../../layouts/userContext";

// import DataTableState from "./Views/DataTableState";
// import HTMLSourceState from "./Views/HTMLSourceState";
// import HTMLState from "./Views/HTMLState";
// import NoMatchState from "./Views/NoMatchState";


const components = {};

function importAll (r) {
	// console.log(r)
	r.keys().forEach(key => {
		const key1 = key.replace("./", "").split(".").slice(0, -1).join(".");
		components[key1] = r(key)
	});
}

importAll(require.context('../../states/', false, /\.js$/));

class LayoutMain extends Component {

	render() {

		return <UserContext.Consumer>
					{((context) => 
		{
			let main = mainPage();

			let routing = [];
			routing = concatRouting();


			let routers = [], overs = [], grands = [];

			//console.log(routing);
			routing.forEach((e, i) =>
			{
				if(e.children && e.children.length > 0) {
					e.children.forEach( ( elem, n ) => {
						if(elem.children && elem.children.length > 0) {
							elem.children.forEach((element, nn) => grands.push( this.searchRouteData(context, nn, e, elem, element )) );
						}
						overs.push( this.searchRouteData(context, n, e, elem ) );
					});
				}
				routers.push( this.searchRouteData(context, i, e ) );
			});

			const NoMatchState = components["NoMatchState"].default;
			return <Switch>
				{ this.searchRouteData(context, 0, main, null, null, "") }
				{ grands }
				{ overs }
				{ routers }
				
			</Switch>
		})}
		</UserContext.Consumer>
	}

	/* 
		@i - (string | int) key index
		@e - (object)- layouts.json element (in menu, profile, extended_routes, bells, comments, help)
		@child - (object) child of @e
		@grandchild - (object) child of @child
		@forceRoute - routee's URL forced up the object.route
		return Route 
	*/
	searchRouteData( context,
					 i,
					 e=undefined,
					 child=undefined,
					 grandchild=undefined,
					 forceRoute=undefined
	) {
		const routeData1 = routeData(e, child, grandchild, forceRoute);
		if( isCapability(routeData1.capability, context.user) ) {
			return;
		}else{
			return this.returnedRoute(i, routeData1.currentE, routeData1.preroute, routeData1.route, routeData1.noexact_route);
		}

	}

	returnedRoute(i, currentE, preroute, route, noexact_route){
		const is =   	preroute + '/' +  route 		== this.props.location.pathname
			|| 	preroute + '/' +  route + "/" 	== this.props.location.pathname;

		const DataTableState = components["DataTableState"].default;
		const NoMatchState = components["NoMatchState"].default;
		const HTMLState = components["HTMLState"].default;
		const HTMLSourceState = components["HTMLSourceState"].default;

		if( currentE.component )
		{
			if(currentE.variables)
				console.log( currentE );
			const Component = components[currentE.component].default;
			//{ ...currentE }
			//TODO роутинг работает дважды
			return [
				<Route
					strict
					path = { preroute + '/' +  noexact_route }
					component = { routeProps => (
						<Component
							user={this.props.user}
							data_type = {currentE.data_type}
							variables = {currentE.variables}
							query = {currentE.query}
							style_id={currentE.style_id}
							is_left={currentE.is_left}
							route={ preroute + '/' + route  }
							onChangeStyle={ style => this.props.onChangeStyle( style ) }
						/>
					)}
					key={i + "_1"}
				/>,
				<Route
					exact
					path = { preroute + '/' +  route }
					render = { routeProps => (
						<Component
							user={this.props.user}
							data_type = {currentE.data_type}
							variables = {currentE.variables}
							query = {currentE.query}
							style_id={currentE.style_id}
							is_left={currentE.is_left}
							route={ preroute + '/' + route  }
							onChangeStyle={ style => this.props.onChangeStyle( style ) }
						/>
					)}
					key={i + "_2"}
				/>
			]
		}
		else if( currentE.html_source )
		{
			return <Route
				exact
				path = { preroute + '/' +  route }
				render = { routeProps => (
					<HTMLSourceState
						{ ...currentE }
						onChangeStyle={ style => this.props.onChangeStyle( style ) }
					/>
				)}
				key={i}
			/>
		}
		else if( currentE.html )
		{
			return [
				<Route
					exact
					path = { preroute + '/' +  route }
					render = { routeProps => (
						<HTMLState
							{...currentE}
							onChangeStyle={ style => this.props.onChangeStyle( style ) }
						/>
					)}
					key={i + "_2"}
				/>
			]
		}
		else if( currentE.data_type)
		{
			//console.log( currentE);

			return [
				<Route
					strict
					path = { preroute + '/' +  noexact_route }
					render = { routeProps => (
						<DataTableState
							title={currentE.title}
							panel={currentE.panel}
							icon={currentE.icon}
							data_type={currentE.data_type}
							query = {currentE.query}
							route={ preroute + '/' + route  }
							is_list={ currentE.is_list  }
							is_list_new={ currentE.is_list_new  }
							style_id={currentE.style_id}
							is_left={currentE.is_left}
							onChangeStyle={ style => this.props.onChangeStyle( style ) }
						/>
					)}
					key={i + "_2"}
				/>,
				<Route
					exact
					path = { preroute + '/' +  route }
					render = { routeProps => (
						<DataTableState
							title={currentE.title}
							panel={currentE.panel}
							icon={currentE.icon}
							data_type={currentE.data_type}
							query = {currentE.query}
							route={preroute + '/' + route }
							is_list={ currentE.is_list }
							is_list_new={ currentE.is_list_new }
							style_id={currentE.style_id}
							is_left={currentE.is_left}
							onChangeStyle={ style => this.props.onChangeStyle( style ) }
						/>
					)}
					key={i + "_1"}
				/>
			]
		}
		return;
		return <Route
			exact
			path = { preroute + '/' +  route }
			render = { routeProps => (
				<NoMatchState
					title={"404"}
					icon={""}
					style_id={currentE.style_id}
					is_left={currentE.is_left}
					onChangeStyle={ style => this.props.onChangeStyle( style ) }
				/>
			)}
			key={i}
		/>;
	}

}

export default compose(
	withRouter
)(LayoutMain);