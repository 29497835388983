import React, {Component, Fragment} from "react";

import $ from "jquery";

import LayoutLinks from "./LayoutLinks";
import LayoutHelp from "./LayoutHelp";
import LayoutBells from "./LayoutBells";
import LayoutComments from "./LayoutComments";
import LayoutHeaderMenu from "./LayoutHeaderMenu";
import LayoutUser from "./LayoutUser";

import { NavLink } from 'react-router-dom';
import { Popover, Menu, MenuDivider, MenuItem,Button, Intent } from "@blueprintjs/core";

import {__} from "../../layouts/utilities/i18n";
import {existRouting} from "../../layouts/routing";
import {name} from "../../layouts/app";
import LayoutIcon from "../../layouts/LayoutIcon";
import {avatar, iconHeight, iconUrl, iconWidth} from "../../layouts/template";


class LayoutHeader extends Component
{
	state = {
		isOpen:false,
		height:0,
		current:this.props.current,
		user:this.props.user,
		isHumburger:false,
		fixed:false
	}

	componentDidMount() 
	{
		window.layoutHeader = this;
		window.addEventListener('scroll', this.onscrollHandler);
	}
    componentWillUnmount() 
	{
		window.removeEventListener('scroll', this.onscrollHandler);
	}
	onscrollHandler(e)
	{
		//console.log( window.scrollY );
		window.layoutHeader.setState({fixed: window.scrollY > 86});
	}
	componentWillReceiveProps (nextProps )
	{
		if(nextProps.current !== this.state.current || nextProps.user !== this.state.user )
			this.setState({
				current: nextProps.current,
				user: nextProps.user
			});
	}
	render()
	{
		const help = existRouting("help") ? <LayoutHelp /> : null;
		const bells = existRouting("bells") ? <LayoutBells /> : null;
		const comments = existRouting("comments") ? <LayoutComments /> : null;
		const links = <LayoutLinks/>;
		const fixedClass = this.state.fixed ? "layout-header fixed " : 'layout-header'; 
		return <div className={fixedClass} >
			<div className="layout-header-inner">
				<div className="layout-left">
					<NavLink to="/" style={{ display:"flex" }} className="mainLink">
						<div 
							className="layout-header-icon " 
							style={{ 
								backgroundImage:iconUrl(),
								height: iconHeight(),
								width: iconWidth(),
							}}
						/>
						<div className="layout-header-title">
							{name()}
						</div>
					</NavLink>
					<div className="humburger" onClick={this.onHumburger}>
						<i className={"fas " + (this.state.isHumburger ? "fa-times" : "fa-bars")} />
					</div>
				</div>
				<div className={ "d-flex-menu " + (this.state.isHumburger ? "open": "" ) }>
					<LayoutHeaderMenu onHumburger={this.onHumburger} user={ this.state.user }/>
				</div>
				<div 
					className={ "layout-menu-right " + (this.state.isHumburger ? "open": "" ) } 
					id="layout-menu-right"
				>
					{links}
					{help}
					{bells}
					{comments}
					<LayoutUser
						current={ this.state.current }
						onCurrent={ this.props.onCurrent }	
						user={ this.state.user }
						refetchUser = {this.props.refetchUser}
						avatar={ avatar() }
						isOpen={ window.screen.width < 600 }
					/>
				</div>
			</div>
		</div>
	}
	onHumburger = () =>
	{
		var totalHeight = 0;
		$("#layout-menu-right").children().each(function(){
			totalHeight += $(this).outerHeight(true); // true = include margins
		});
		console.log( totalHeight );
		this.setState({isHumburger:!this.state.isHumburger, totalHeight: this.state.isHumburger ? totalHeight : 0 });
	}
}
export default LayoutHeader;