import React, {Component, Fragment} from 'react';
import {withRouter} from 'react-router-dom';
import YandexMap from "./YandexMap";
import NewDialog from "./NewDialog";
import ClusterDialog from "./ClusterDialog";
import {Callout, Dialog, Intent, Tabs, Tab } from "@blueprintjs/core"; 
import {__} from "../../layouts/utilities/i18n";
import PlaceTabs from "./PlaceTabs";
import Pagi from "../../layouts/utilities/Pagi";
import {sprintf} from "../../layouts/utilities/sprintf";
import CatalogList from "./CatalogList";
import customsource from "../SCMainState/customsource";
import Banners from '../Banners';
import $ from "jquery";
import {getQueryArgs, getQueryName, queryCollectionFilter} from "../../layouts/schema";

import { geoDefaultPosition} from "../../layouts/map";

const query_name = getQueryName("Place")
const query_args = getQueryArgs("Place")
const getPlaces = queryCollectionFilter( "Place", query_name, query_args, true );

const Legends = (props) => {
    const [state, setState] = React.useState({
        isOpen:false,
        height: 40
    });
    const onToggled = evt =>
    {
        setState({ isOpen : !state.isOpen, height: !state.isOpen ? 'initial' : 40 });
    }
    return <div 
        onClick={ onToggled }
        style={{height : state.height, cursor: 'pointer'}}
    >{props.placeTypes.map((e, i) =>
    {
        return <span className="mr-2" style={{color: e.color}} key={i}>
            { __(e.title) }
        </span>
    })}</div>;
}

class Places extends Component
{

   constructor(props) {
       super(props);
       this.state = {
           count:10,
           offset: 0,
		   width:0,
		   height:0,
           position:null,
		   currentTab: "ng"
       };
   }

   onQueryString() {
        console.log(this.props.location.search);
        const matches = this.props.location.search.match(/^\?id\=(.*)$/);
        if (matches) {
            this.getPlaceDialog(matches[1]);
        } else {
            this.setState({isOpen:false });
        }
   }

   componentDidUpdate(prevProps) {
       if (this.props.location.search != prevProps.location.search) {
           this.onQueryString();
       }
   }

    componentDidMount ()
    {
        this.updateWindowDimensions();
        //this.updateWindowscroll();
        window.addEventListener('resize', this.updateWindowDimensions);
        window.addEventListener('scroll', this.updateWindowscroll);
        this.onQueryString();
    }
    componentWillUnmount()
    {
        window.removeEventListener('resize', this.updateWindowDimensions);
        window.removeEventListener('scroll', this.updateWindowscroll);
    }
    updateWindowDimensions = () =>
    {
        this.setState({
            width : document.body.clientWidth,
            height: document.body.clientHeight
        });

    }
    updateWindowscroll = () =>
    {
		return;
        const scroll = (window.scrollY);
        if(scroll + 100 > $("#TabsExample").offset().top)
        {
            $("#TabsExample .bp3-tab-list").addClass("fixed");
        }
        else
        {
            $("#TabsExample .bp3-tab-list").removeClass("fixed");
        }
    }
    getPlaceHeader = ((el, isBabel=false) =>
    {
        const flag = el.is_events_active ? '<div class="_flag float-right"></div>' : '';
        //console.log(el);
        const type = el.types && el.types.length > 0 ? el.types[0] : {};
        return isBabel
            ?
            <Fragment>
				<span className='small text-primary'>
					<i className='fas fa-map-marker-alt'/>
                    { __(type.title) }
				</span>
                {el.title}
                {flag}
            </Fragment>
            :
            "<span class='small' style='color:" + type.color + "'><i class='fas fa-map-marker-alt'></i> " + __(type.title) + "</span> " + el.title + flag
    })

    onFinishNew = () =>
    {
        this.setState({ startDND : { hideMarker:true, isDragging:false } });
    }
    onStartCreate = data =>
    {
        console.log("onStartCreate", data);
        this.setState({
            isClusterOpen:false,
            isNewOpen:true,
            newTitle: data.title,
            newData:{geo: data.new_mark_coords, DNDType: data.DNDType}
        });
    }

    onCreate = (data, DNDType) =>
    {
        console.log(data);
        let pr = {};
        pr.title = data.title;
        if(data.id)
            pr.id = data.id;
        switch(DNDType)
        {
            case "Place":
                console.log(data);
                pr.geo 				= data.geo;
                pr.address 			= data.address;
                pr.description 		= data.description;
                pr.file 		= data.file;
                pr.types =  data.types ? data.types : null;
                this.props.changePlace({
                    variables: pr,
                    update: (store, { data: { changePlace } }) =>
                    {
                        console.log( changePlace );
                        this.setState({isNewOpen : !this.state.isNewOpen});

                    },
                    refetchQueries: [
                        { query: getPlaces, variables: {}}
                    ]
                });

                break;
        }
    }
    onCancelCreate = () =>
    {
        this.setState({
            startDND: {
                hideMarker:true,
                isDragging:false,
                DNDIconX:1,
                DNDIconY:1,
                deletePlace:true
            },
            isNewOpen:false
        });
    }

    allPlaces = () =>
    {
        const cluster = this.props.places.map(e => {
            console.log(e);
            return { data_type:"place", data_id:e._id, balloonContentHeader:this.getPlaceHeader( e ) , ...e}
        });
        //console.log(cluster);
        this.setState({
            isClusterOpen : !this.state.isClusterOpen,
            clusterTitle:  __("All Places"),
            cluster
        });
    }

    render () 
	{
        const placeTypes 	= this.props.placeTypes || [];
        const places 		= this.props.places || [];

        const placesList = places.length > 0
            ?
            <Fragment>
                <div className="container">
                    <div className="row brdr">
                        <div className="col-md-6  ">
                            <Pagi
                                all={parseInt( places.length / this.state.count) }
                                current={this.state.offset}
                                onChoose={this.onPagi}
                            />
                        </div>
                        <div className="col-md-6 text-right d-flex align-items-center justify-content-md-end justify-content-center">
                            {
                                sprintf(
                                    __("Показаны результаты %s - %s"),
                                    this.state.offset * this.state.count + 1,
                                    (this.state.offset + 1) * this.state.count 
                                )
                            }
                        </div>
                    </div>
                </div>
				<div className="row">
					<div className="col-md-8">
						<CatalogList
							items={ places}
							offset={this.state.offset}
							count={this.state.count}
							onShowMap={this.onShowMap}
							onShowRegion={this.onShowRegion}
							getPlaceDialog={this.getPlaceDialog}
						/>
                    </div>
					<div className="col-md-4 mt-2">
						<Banners isVertical={true} count={ places.length >= 10 ? 10 : places.length } />
					</div>
                </div>
				
                <div className="container">
                    <div className="row brdr mb-2">
                        <div className="col-md-6">
                            <Pagi
                                all={parseInt( places.length/ this.state.count) }
                                current={this.state.offset}
                                onChoose={this.onPagi}
                            />
                        </div>
                        <div className="col-md-6 text-right d-flex align-items-center justify-content-md-end justify-content-center">
                            {
                                sprintf(
                                    __("Показаны результаты %s - %s"),
                                    this.state.offset * this.state.count + 1,
                                    (this.state.offset + 1) * this.state.count 
                                )
                            }
                        </div>
                    </div>
                </div>
            </Fragment>
            :
            <Callout intent={Intent.DANGER} className="text-center m-4">
                {__("Ни одного объекта не обнаружено")}
            </Callout>
		
        return <Fragment>
			
			<Tabs id="TabsExample" animate={false} onChange={this.handleTabChange} selectedTabId={this.state.currentTab}>
				<Tab 
					id="ng" 
					title=<span>
						<img className="icon-list mr-1" src="/assets/img/sc/icon-list.svg" />
						Список
					</span> 
					panel={
						<div className="places-list">
							{placesList}
						</div>
					} 
				/>
				<Tab 
					id="mb" 
					title=<span>
						<img className="icon-map mr-1" src="/assets/img/sc/icon-map.svg" />
						Карта
					</span>  
					panel={
						<Fragment>
							<YandexMap
								{...this.props}
								position={this.state.position}
								context={this}
								startDND={ this.state.startDND ? this.state.startDND : { hideMarker:true, isDragging:false } }
								onFinishNew={this.onFinishNew}
								onStartCreate={this.onStartCreate}
								onInit={this.onInit}
							/>
							<div className="font-weight-bold m-1">
								<Legends placeTypes={placeTypes}/>
							</div>
							<Banners count={8}/>
						</Fragment>
					}  
				/>
			</Tabs>
            <div className="position-absolute">
                <NewDialog
                    isOpen={this.state.isNewOpen}
                    title={this.state.newTitle}
                    {...this.state.newData}
                    place_types={this.props.place_types}
                    onCreate={this.onCreate}
                    onCancel={this.onCancelCreate}
                    toggle={() => this.setState({isNewOpen : !this.state.isNewOpen}) }
                />
                <ClusterDialog
                    toggleDialogCluster={this.toggleDialogCluster}
                    onNew={this.onNew}
                    getPlaceDialog={this.getPlaceDialog}
                    getEventDialog={this.getEventDialog}
                    getTeamDialog={this.getTeamDialog}
                    clusterTitle={this.state.clusterTitle}
                    cluster={this.state.cluster}
                    isClusterOpen={this.state.isClusterOpen}
                    onStartCreate={this.onStartCreate}
                />
                <Dialog
                    icon="map-marker"
                    isOpen={ this.state.isOpen }
                    onClose={ this.toggleDialog }
                    title={<div>
						<span
                            className=" mr-3"
                            style={{
                                color: this.state.place && this.state.place.types
                                    ? this.state.place.types[0].color
                                    : "#777"
                            }}
                        >
							{__(this.state.place && this.state.place.types ? this.state.place.types[0].title : "Place")}
						</span>
                        { this.state.dialogTitle }
                    </div>}
                    style={{zIndex:1000}}
                >
                    <div className="pt-dialog-body p-4">
                        <PlaceTabs
                            onClose={ this.toggleDialog }
                            onEvent={ this.getEventDialog }
                            onMerged={this.onMerged}
                            onShowMap={this.onShowMap}
                            { ...this.state.place }
                        />
                    </div>
                </Dialog>
            </div>
        </Fragment>
    }
	
	handleTabChange = tabId =>
	{
		this.setState({currentTab:tabId});
	}
    switched = evt =>
    {
        const tab = evt.currentTarget.getAttribute("tab");
        this.setState({ tab });
    }
    onPagi = offset =>
    {
        console.log(offset);
        this.setState({offset});
    }
    onShowMap = (data) =>
    {
        console.log(data);
        this.props.history.push('/');
        const default_position = geoDefaultPosition();
        this.setState({
            tab		: "2",
            currentTab: "mb",
            isOpen  : false,
            position: [ data.geo[0], data.geo[1], 17 ]
        });
    }
    onShowRegion = regionArray =>
    {
        console.log(regionArray);
    }
    getPlaceDialog = (n) =>
    {
        console.log(n);
    }

    getPlaceDialog = id =>
    {
        const _places = this.props.places || [];
        const place = _places.filter(e => e._id == id)[0];
        if(!place) return;
        // this.setState({ tab: 2 });
        //console.log(place);
        // this.onShowMap(place);
        // this.props.history.push('/?id=' + place._id);
        this.setState({ isOpen:!this.state.isOpen, dialogTitle : place.title, place, isClusterOpen: false });
    }
    toggleDialog = () => {
        if (this.state.isOpen) {
            this.props.history.push('/');
        }
    }


    getClusterDialog( geoObjects, clusterTitle="Cluster" )
    {
        console.log(geoObjects);
        let cluster = Array.isArray(geoObjects) ? geoObjects.map((e, i) => {
            return {...e.properties._data};
        }) : null;
        console.log( cluster );
        this.setState({
            isClusterOpen : (cluster ? !this.state.isClusterOpen : false),
            clusterTitle,
            cluster
        });
    }
    toggleDialogCluster = () => this.setState({ isClusterOpen:!this.state.isClusterOpen });

    /*  */
    onInit = yamdexMap =>
    {
        const context = this;
        if(yamdexMap)
        {
            this.setState({bounds: yamdexMap.getBounds(true)});
            var suggestView1 = new window.ymaps.SuggestView( 'suggest1' );
            suggestView1.events.add(
                "select",
                evt =>
                {
                    const value = evt.get("item").value;
                    //console.log( value );
                    window.ymaps.geocode( value )
                        .then(
                            function (res)
                            {
                                context.setState({

                                    // searchAdress:value,
                                    // geo:res.geoObjects.get(0).geometry._coordinates
                                });
                                context.props.onChangeSearchParam("searchAdress", value);
                                context.props.onChangeSearchParam("geo", res.geoObjects.get(0).geometry._coordinates);

                            },
                            function (err)
                            {
                                console.log(err);
                            }
                        );
                }
            );
        }
    }


}

export default withRouter(Places)