import React from "react";
import widgets from "./getWidgets";
import { template } from "../template";

export default function getWidget( widget, templatePart)
{ 
	const ts = template()[templatePart];	
	if( ts && Array.isArray(ts) )
	{
		const w = ts.filter(e => e.component == widget);
		if( !w[0] )return null;			
		const _Widget = widgets[ w[0].component ].default;
		return <_Widget {...w[0]} />
	}
	else
	{
		return null;
	}
}
export function initWidget(widget, data={})
{
	const _Widget = widgets[ widget ].default;
	return <_Widget {...data} />
}
export function initArea(areaName, data={}, defArea=null)
{
	const ts = template()[ areaName ];	
	if( ts && Array.isArray(ts) && ts.length > 0 )
	{
		return ts.map((e, i) => 
		{
			const _Widget = widgets[ e.component ].default;
			return <_Widget {...e} {...data} key={i}/>
		});		
		
	}
	else
	{
		return defArea;
	}
}
