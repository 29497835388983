import React from 'react';

export default class DNDIcon extends React.Component 
{
	state = {
		isDragging: this.props.isDragging,
		originalX:  0,
		originalY:  0,
		translateX: this.props.translateX,
		translateY: this.props.translateY,
		lasttranslateX: this.props.translateX,
		lasttranslateY: this.props.translateY
	};
	
	componentWillMount() 
	{
		if( !this.props.isDragging ) return;
		window.addEventListener('mousemove', this.handleMouseMove);
		window.addEventListener('touchmove', this.handleMouseMove);
		window.addEventListener('mouseup', this.handleMouseUp);
		window.addEventListener('touchend', this.handleMouseUp);
	}
	componentWillUnmount() 
	{
		window.removeEventListener('mousemove', this.handleMouseMove);
		window.removeEventListener('touchmove', this.handleMouseMove);
		window.removeEventListener('mouseup', this.handleMouseUp);
		window.removeEventListener('touchup', this.handleMouseUp);
	}
	componentWillReceiveProps ( nextProps )
	{
		if( nextProps.isDragging || nextProps.translateX )
		{
			const st = {
				//...nextProps,
				isDragging : nextProps.isDragging,
				style : nextProps.style,
				translateX : nextProps.translateX,
				translateY : nextProps.translateY,
				lasttranslateX : nextProps.translateX,
				lasttranslateY : nextProps.translateY
			}
			this.setState(st);
			//console.log(st);
			window.removeEventListener('mousemove', this.handleMouseMove);
			window.removeEventListener('touchmove', this.handleMouseMove);
			window.removeEventListener('mouseup', this.handleMouseUp);
			window.removeEventListener('touchend', this.handleMouseUp);
			if( nextProps.isDragging)
			{
				window.addEventListener('mousemove', this.handleMouseMove);
				window.addEventListener('touchmove', this.handleMouseMove);
				window.addEventListener('mouseup', this.handleMouseUp);
				window.addEventListener('touchend', this.handleMouseUp);
			}
		}
	}
	handleMouseDown = ({ clientX, clientY }) => 
	{
		window.addEventListener('mousemove', this.handleMouseMove);
		window.addEventListener('touchmove', this.handleMouseMove);
		window.addEventListener('mouseup', this.handleMouseUp);
		window.addEventListener('touchend', this.handleMouseUp);

		if (this.props.onDragStart) {
			this.props.onDragStart();
		}

		this.setState({
			originalX: clientX,
			originalY: clientY,
			isDragging: true
		});
	};

	handleMouseMove = ({ clientX, clientY }) => {
		const { isDragging } = this.state;
		const { onDrag } = this.props;
		
		if (!isDragging) 
		{
			return;
		}
		this.setState(
			prevState => ({
				translateX: clientX - prevState.originalX + prevState.lasttranslateX,
				translateY: clientY - prevState.originalY + prevState.lasttranslateY
			})
			/**/, 
			() => {
				if (onDrag) {
					onDrag({
						translateX: this.state.translateX,
						translateY: this.state.translateY
					});
				}
			}
			
		);
	};

	handleMouseUp = (e) => {
		window.removeEventListener('mousemove', this.handleMouseMove);
		window.removeEventListener('mouseup', this.handleMouseUp);
		window.removeEventListener('touchmove', this.handleMouseMove);
		window.removeEventListener('touchend', this.handleMouseUp);
		
		let coords = e.clientX ? e : e.changedTouches[0]

		this.setState(
			{
				originalX: 0,
				originalY: 0,
				lasttranslateX: coords.pageX,
				lasttranslateY: coords.pageY,
				translateX: coords.pageX,
				translateY: coords.pageY,

				isDragging: false
			},
			() => {
				if (this.props.onDragEnd) {
					this.props.onDragEnd( this.state );
				}
			}
		);
	};

	render() 
	{
		const { translateX, translateY, isDragging } = this.state;
		//console.log(this.props.style);
		return (
			<div 
				style={ {  
					marginTop:-100,
					marginLeft:-20,
					padding:"6px 9px",
					cursor:"pointer",
					transform: `translate(${translateX}px, ${translateY-20}px)`,
					...this.props.style
				} }
				onMouseDown={ this.handleMouseDown }
			>
				<i 
					className="fas fa-map-marker-alt fa-2x text-light"
				/>
			</div>
		);
  }
}