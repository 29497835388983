import React, {Component} from 'react';
import {compose} from "recompose";
import { Query, graphql, withApollo} from "react-apollo";
import {withRouter} from "react-router";
import {loader} from "graphql.macro";
import Loading from "../layouts/utilities/Loading";
import {assertion_token} from "../layouts/config";
import LayoutBody from "./LayoutBody";

const l_token = loader("../layouts/schema/graphql/token.graphql");

class LayoutAccessLoading extends Component
{

    state = {client_token : false }

    constructor(props)
    {
        super(props);
    }

    componentWillMount() {
        if (assertion_token()) {
            this.props.token({
                variables:
                    {
                        input: {
                            grant_type: "jwt-bearer",
                            assertion: assertion_token()
                        }
                    },
                update: (store, { data: { token } }) =>
                {
                    localStorage.setItem(
                        'client_token',
                        token.access_token
                    );

                    this.setState({"is_client_token": true });

                }
            })
        }
    }

    render()
    {
        if(assertion_token()){

            if(this.state.is_client_token)
            {
                return (
                    <LayoutBody/>
                );
            }else{
                return <Loading/>;
            }
        }else{
            return (<LayoutBody/>);
        }

    }
}


export default compose(

    graphql(l_token, {"name": "token"}),
    withApollo,
    withRouter
)(LayoutAccessLoading);