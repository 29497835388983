import React, {Component} from 'react';
import BasicState from "../layouts/BasicState";
import {__} from "../layouts/utilities/i18n";
import Banners from './Banners';
import customsource from './SCMainState/customsource';

class SCGraditudeState extends Component { 
	render()
	{
		return <div className="layout-state">
			<div className="layout-state-head">
				<div className="container px-3 d-flex justify-content-center flex-column">
				<h1 className="py-3 text-center">Спасибо!</h1>
				Создание этого сайта стало возможно благодаря поддержке людей, поддержавших наш краудфандинг на planeta.ru. Мы благодарим за поддержку каждого из вас:
				<div className="d-flex py-3 flex-row flex-wrap">
				<ul>
					<li>
					anutka_ger</li>
					<li>
					sergey.podolniy</li>
					<li>
					nl1ice</li>
					<li>
					d.vyuda</li>
					<li>
					prkonst</li>
					<li>
					alt.tertel</li>
					<li>
					lele1984</li>
					<li>
					romannn93</li>
					<li>
					kriss2001</li>
					<li>
					altair_anna</li>
					<li>
					Jliia Shishkina</li>
					<li>
					lena_kopeysk</li>
					<li>
					Дмитрий</li>
					<li>
					eddie0474</li>
					<li>
					lev.shushpanov</li>
					<li>
					svetlana.planeta</li>
					<li>
					botalov</li>
					<li>
					klim666683</li>
					<li>
					enonome</li>
					<li>
					d.kravchenko</li>
					<li>
					146alesh</li>
					<li>
					zaoqueen</li>
					<li>
					ovkraynova</li>
					<li>
					boginv</li>
					<li>
					kingsbaby</li>
					<li>
					ziminam</li>
					</ul>
					<ul>
					<li>
					dr.smirnov</li>
					<li>
					irish24</li>
					<li>
					lae4ka</li>
					<li>
					kat.naschekina</li>
					<li>
					lana.inform</li>
					<li>
					gtitov13</li>
					<li>
					e.otchik</li>
					<li>
					annyone.else</li>
					<li>
					marinalurye</li>
					<li>
					natalyamironova18</li>
					<li>
					Michael Rybakov</li>
					<li>
					konkurs-mgpu</li>
					<li>
					9181014693</li>
					<li>
					kkleverclub</li>
					<li>
					marnika2020</li>
					<li>
					Ольга Зверева</li>
					<li>
					volshebnikland</li>
					<li>
					ombrel</li>
					<li>
					Семёнычев Алексей</li>
					<li>
					anna-mai</li>
					<li>
					anna.peresvetova</li>
					<li>
					nata888.trofimova</li>
					<li>
					totskaya-lena</li>
					<li>
					mefo</li>
					<li>
					diese.elle</li>
					<li>
					olgate</li>
					</ul>
					<ul>
					<li>
					alexandr.klyagin</li>
					<li>
					s.list1971</li>
					<li>
					od_kovalenko</li>
					<li>
					annabriz</li>
					<li>
					natalgor20</li>
					<li>
					mouse2010</li>
					<li>
					danilkina1978</li>
					<li>
					volko.nastia2012</li>
					<li>
					ermo82</li>
					<li>
					vl</li>
					<li>
					atsapina</li>
					<li>
					pya-ekaterina</li>
					<li>
					masmv</li>
					<li>
					Павел</li>
					<li>
					alena2909</li>
					<li>
					vrudneva</li>
					<li>
					kotoffsfsmily</li>
					<li>
					slovva.r</li>
					<li>
					elena.zav</li>
					<li>
					roughflow</li>
					<li>
					lu3la</li>
					<li>
					krassnz</li>
					<li>
					lfell</li>
					<li>
					o.k.kirsanova</li>
					<li>
					aizikovich</li>
				</ul>
				</div>
				</div>
				
			</div>
			{<Banners
				items={customsource.banners}
			/>}
		</div>
	}
}

export default SCGraditudeState