import React, {Component} from "react";
import { NavLink } from 'react-router-dom';
import { withRouter } from "react-router";
import {concatRouting} from "../../layouts/routing";
import {__} from "../../layouts/utilities/i18n";
import {isCapability} from "../../layouts/user";
import {template} from "../../layouts/template";

class LayoutMenuMain extends Component
{
	state = { isOpen : this.props.isOpen, current:this.props.current };
	componentWillReceiveProps (nextProps )
	{
		if(
				nextProps.current !== this.state.current 
			|| 	nextProps.isOpen !== this.state.isOpen 
		)
			this.setState({
				current: nextProps.current,
				isOpen: nextProps.isOpen
			});
	}
	render()
	{
		let chldrn = [], grndchldrn = [], isOpen = false;
		const firstRoute = this.getParent();
		const childrenss = this.getChildren();
		if(childrenss && !firstRoute[0].hide_slider && template().left_menu == "pictogramm")
		{
			
			chldrn = childrenss.map((e, i) => {
				const isRole =
				isCapability(e.capability, this.props.user);
				grndchldrn = this.getGrandChildren( e );
				if(isRole) return "";
				return <NavLink
					className="list-element" 
					activeClassName="active"
					to={ "/" + this.getFirstRoute() + "/" + e.route }
					key={i}
				>			
					{__(e.title)}
					{
						grndchldrn ?
							grndchldrn.map((element, ii) => <NavLink 
								className="list-element-child" 
								activeClassName="active"
								to={ "/" + this.getFirstRoute() + "/" + e.route + "/" + element.route }
								key={ii}
							>
								{__(element.title)}
							</NavLink> )
							:
							null
					}
				</NavLink> 
			});
			isOpen = true;
		}	
		return <div className={ "layout-menu-main" +( isOpen ? " open " : "" ) } >
			{chldrn}
		</div>
	}
	getFirstRoute()
	{
		const url = this.props.location.pathname.split("/")[1];
		return url ? url : "";
	}
	getParent()
	{
		const rts = this.getFirstRoute();
		//console.log(rts);

		let routing = [];
		routing = concatRouting();

		return routing.filter( e => e.route === rts);
	}
	getGrandChildren( chldrn )
	{
		if(!chldrn) return false;
		//console.log(chldrn);
		if( chldrn.children && chldrn.children.length > 0 )
		{
			return chldrn.children;
		}
		else
			return false;
	}
	getChildren()
	{
		const chldrn = this.getParent();
		if(chldrn.length > 0)
		{
			//console.log( chldrn[0].children );
			if( chldrn[0].children && chldrn[0].children.length > 0 )
			{
				return chldrn[0].children;
			}
			else
				return false;
		}
		else
		{
			return false;
		}
	}
}
export default withRouter(LayoutMenuMain);