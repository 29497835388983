import React, {Component} from 'react';
import {cssStyle, loginPage} from "../layouts/template"
import {compose} from "recompose";
import { Query, graphql, withApollo} from "react-apollo";
import {withRouter} from "react-router";
import {loader} from "graphql.macro";
import {AppToaster} from "../layouts/utilities/blueUtils";
import {Intent} from "@blueprintjs/core";

import LayoutContent from "./LayoutContent";
import LayoutHeader from "./LayotHeader";
import LayoutFooter from "./LayoutFooter";

import {__} from "../layouts/utilities/i18n";
import Loading from "../layouts/utilities/Loading";
import {title} from "../layouts/app";
import {
	queryUserInfo, getQueryName, getQueryArgs,
	queryCollection, querySingle,
	getChangeName, getInputTypeName, getMutationArgs,
	mutationAdd, mutationEdit, mutationDelete
} from "../layouts/schema";
import {isLoggedPage} from "../layouts/user";
import layouts from "../layouts/layouts";
import UserContext from "../layouts/userContext";


class LayoutBody extends Component
{
	random;

	constructor(props)
	{
		super(props);
		this.random = Math.random();
		const style = cssStyle();
		//console.log(style);
		const fl = localStorage.getItem("fluid");
		//console.log(typeof fl, fl);
		const fluid = typeof fl !== "undefined" ? fl == "1" : 1;
		this.state={style, fluid }

		document.title = title();

		const token = localStorage.getItem( 'token');

		if(!token && isLoggedPage(props.location.pathname)){
			this.props.history.push(loginPage());
		}

		this.state["current"] = 100;

	}

	renderContent(context){
		const query = queryUserInfo();
		//console.log(query);
		return <Query query={query}>
		{
			({ loading, error, data, refetch}) => {
				if( loading)
				{
					return <Loading/>;
				}
				let user = null;
				if(data) {
					
					if(data.userInfo){
						user = data.userInfo
						if(data.userInfo.user){
							user = data.userInfo.user;
							console.log(data);
						}else{
							user = data.userInfo
						}
					}
				}
				if (!error) {
					context.setUser(user);
				}
				if(error)
				{
					console.log(error);
				}

				const token = localStorage.getItem( 'token');
				if(!token && isLoggedPage(this.props.location.pathname)){
					this.props.history.push(loginPage());
					return null;
				}
				return <div className={"container" + (this.state.fluid ? "-fluid ": "") +" cont"}>
				<div className="layout block w-100">
					<LayoutHeader
						name={this.props.name}
						current={this.state.current}
						onCurrent={this.onCurrent}
						user={user}
						refetchUser={refetch}
					/>
					<LayoutContent
						current={this.state.current}
						onCurrent={this.onCurrent}
						user={user}
						onChangeStyle={ style => this.onChangeStyle( style ) }
						refetchUser={refetch}
					/>
					<LayoutFooter/>
				</div>
			</div>;
			}
		}
		</Query>;
	}

	render()
	{
		//console.log(this.state);
		let random = this.random;
		return (
			<div className="full">
				<header>
				</header>
				<main>
					<UserContext.Consumer>
					{((context) => 
						{return this.renderContent(context)}
					)}
					</UserContext.Consumer>
				</main>
				<footer>
				</footer>
				<div>
					<link href={"/assets/css/style.css?" + random} rel="stylesheet" />
					<link rel="stylesheet" type="text/css" href={ this.state.style + "?" + random }/>
				</div>
			</div>
		);
	}
	onChangeStyle = style =>
	{
		//console.log(style);
		if (!style.style) {
			style.style = layouts.template.style;
		}
		localStorage.setItem("css", style.style);
		localStorage.setItem("fluid", style.fluid ? 1 : 0);
		if( 
			style.style !== this.state.style 
		)
		{
			//console.log(style);
			this.setState( {...style} );
		}
	}

	onCurrent = i =>
	{
		this.setState({current:i});
	}
}

export default compose(

	withApollo,
	withRouter
)(LayoutBody);