import React from 'react';
import {NavLink} from "react-router-dom";

class SCFooterWidget extends React.Component {
	render () {
		return <div className="altobr-footer">
		<div className="container-lg px-3 pt-3">
		<div className="row px-0 m-0">
		<div className="col-md-6 col-sm-12 pb-3 px-0">
		<p className="footer-caption m-0">© Альтернативное образование в России, 2020</p>
<p className="footer-caption m-0">Сделано командой Конструкторского Бюро Дома Протопии</p>
</div>
<div className="col-md-6 col-sm-12 px-0 pb-3 d-flex justify-content-md-end">
        <a href="https://vk.com/alternativnoeobrazovanie"><img className="footer-icon-vk px-2" src="/assets/img/sc/icon-vk.svg"></img></a>
        <a href="https://www.facebook.com/groups/alternativnoeobr/"><img className="footer-icon-fb px-2" src="/assets/img/sc/icon-fb.svg"></img></a>
		<a href="https://www.instagram.com/so_alternativa/"><img className="footer-icon-instagram px-2" src="/assets/img/sc/icon-instagram.svg"></img></a>
		<a href="https://www.youtube.com/channel/UC6mYDxAi3caYNyJ58awBqKA"><img className="footer-icon-youtube px-2" src="/assets/img/sc/icon-youtube.svg"></img></a>
</div>
</div>
<div className="row px-0 m-0">
<div className="col-md-6 col-sm-12 px-0 pb-3 ">
<p className="footer-caption m-0">Powered by <a href="#">Protopia Ecosystem</a></p>
<p className="footer-caption m-0">Icons made by <a href="#">Dave Gandy</a> and <a href="#">Freepik</a> from <a href="#">Flaticon</a></p>
</div>
<div className="col-md-6 col-sm-12 px-0 pb-3 d-flex justify-content-md-end align-items-md-end">
<span className="footer-caption">
	<NavLink
		to={ "/login"}
	>
		Войти
	</NavLink>
</span>
</div>
</div>
</div>
	</div>
	}
}

export default SCFooterWidget
