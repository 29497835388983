import React, {Component, Fragment} from "react";
import CategoryForm from "./CategoryForm";


import Moment from 'react-moment';
import { NavLink } from 'react-router-dom';

import { 
	Icon, Tag, 
	Intent, Tooltip, 
	Card, FormGroup, 
	Button, ButtonGroup,
	Position, Popover, 
	InputGroup 
 } from "@blueprintjs/core";
import $ from "jquery";

import { compose, mapProps } from "recompose";
import {graphql, Query, withApollo, Mutation} from 'react-apollo';
import gql from "graphql-tag";
import {withRouter} from "react-router";

import {
	apolloFields,
	getInput, getVisibleValue
} from "../../layouts/schema/ecosystem";
import {__} from "../../layouts/utilities/i18n";

class Row extends Component
{
	state = {
		...this.props.elem,
		current: this.props.current,
		height: this.props.height,
		isOpen: this.props.isOpen,
		allChecked 	: false, 
		checked 	: 0, 
	}
	componentWillReceiveProps ( nextProps )
	{
		if(typeof nextProps.isOpen !== "undefined" )
		{
			this.setState({isOpen:nextProps.isOpen});
		}
		if(typeof nextProps.checked !== "undefined" )
		{
			this.setState({checked:nextProps.checked});
		}
		if(typeof nextProps.allChecked !== "undefined" )
		{
			this.setState({allChecked:nextProps.allChecked});
		}
	}
	render()
	{
		const { trColor, data_type} = this.props;

		return <Mutation mutation={this.props.change_mutation}>
			{( m_change, { data } ) => {
				return (<Mutation mutation={this.props.mutation_delete}>
					{( m_delete, { data } ) => this.onRowForm(m_change, m_delete)}
				</Mutation>)
				}
			}
		</Mutation>;

		/* MUTATION change end */
	}
	onRowForm = ( m_change, m_delete ) =>
	{
		const { trColor, data_type, is_hidden} = this.props;
		const style = { backgroundColor : trColor  };
		
		const isOpen = (this.props.location.pathname == this.props.route + "/" + this.state._id) || (this.props.location.pathname == this.props.route + "/new" && this.props.isNew)

		let html = [];
		if (this.props.location.pathname == this.props.route || isOpen){
			html.push(
					<tr key={"new"}>
					{
						this.rows().map( (e, i) => {
							return this.props.isNew
								?
								<th col={ e[0] } key={i} width={e[2]} style={style}>{ e[1] }</th>
								:
								<td key={i}>
									{ this.onDefRow(e[0], this.state, e )}
								</td>
						})
					}
					</tr>
			);
		};
		if (isOpen) {
			//console.log(this.props.isNew);
			html.push(
				<tr>
					<td colSpan={12}>
						<Card 
							fill={"true"} 
							className={isOpen ? "layout-data-card p-4 mb-1" : "layout-data-card py-0 px-4"} 
							style={{ height: this.state.height }}
							interactive={false} 
						>
							<CategoryForm
								{...this.state}
								ID={this.state._id}							
								data={ this.state }	
								data_type={ data_type }						
								onChange={this.onChange}
								onSave={(state, _id) => this.onSave(m_change, state, _id)}
								onDelete={(_id) => this.onDelete(m_delete, _id)}
								onClose={ this.onClose }
								saveLabel={this.props.isNew ? __("Save") :__("Update")}
								isNew={this.props.isNew}
								isOpen={ isOpen } // for Yandex Maps
								ref={(node) => {this.card = node }}
							/>
						</Card>
					</td>
				</tr> 
			);
		}

		return <Fragment>
				{html}
			</Fragment>
	}
	onSave =(m_change, state, _id)=>
	{		
		console.log(state);
		let input = getInput(state, this.props.data_type);

		console.log(input);
		m_change({
			variables:
			{
				"id": _id, 
				"input": input
			},
			update: (store, { data: data }) =>
			{
				console.log(data);
				const respData = data[this.props.mutation_name];
				if(respData)
				{
					//console.log(state);
					//console.log(respData);
					let _state = {};
					for(let e in respData)
					{
						_state[e] = respData[e];
					}
					//console.log(_state);
					this.setState( _state );
					
					const data1 = store.readQuery({query: this.props.query, variables: {}  });
					const new_state = { ...input, ..._state, _id: respData._id};
					data1[ this.props.query_name ][ this.props.i ] = new_state;
					//console.log("data1:", data1);
					//console.log(new_state)
					store.writeQuery({ query: this.props.query, variables: {}, data: data1 });
					this.props.onOpen(-1);
				}
			},
			refetchQueries: [ { query: this.props.query, variables: {}}]
		});
	}

	onDelete = (m_delete, _id) =>{

		m_delete({
			variables:
				{
					"id": _id,
				},
			update: (store, { data: data }) =>
			{
				console.log(data);
				const respData = data["delete" + this.props.data_type];
				if(respData)
				{
					this.props.onOpen(-1);
				}
			},
			refetchQueries: [ { query: this.props.query, variables: {}}]
		});

		// this.props.onOpen(-1);
	}

	onDefRow( col, elem, dat )
	{

		let txt;
		switch(col)
		{
			case "edit":
				txt = this.props.location.pathname !== this.props.route + "/" + this.state._id
					?
					<NavLink
						to={{
							pathname: this.props.route + "/" + this.state._id
						}}
					>
							<Button
								title={__("Edit")}
								icon="edit"
								intent={Intent.SUCCESS}
								fill={true}
								className="max-width-100"
							/>
					</NavLink>
					:
					<NavLink
						to={{
							pathname: this.props.route
						}}
					>
						<Button
							title={__("Close")}
							icon="chevron-up"
							intent={Intent.SUCCESS}
							fill={true}							
							className="max-width-100"
						/>
					</NavLink>
				break;
			default:
				txt = this.onRow(col, this.state, dat );
		}
		return txt;
	}
	onRow(col, elem, dat )
	{
		let txt;		
		switch(col)
		{
			case "ch":
				txt = <Fragment>
					<label className="_check_blue_ ml-2">
						<input 
							type="checkbox" 
							eid={this.state._id}
							value={this.state._id}
							checked={ this.state.checked } 
							onChange={this.onChecked}
						/>
					</label>
				</Fragment>
				txt = null;
				break;
			case "_id":
				txt = <div 
					className="px-1" 
					title={this.state._id} 
					style={{
						maxWidth:100, 
						textOverflow: "ellipsis", 
						overflow: "hidden", 
						whiteSpace: "nowrap"
					}}
				>
					{this.state._id}
				</div>
				break;
			default:
				const apollo_fields = apolloFields(this.props.data_type);
				switch( apollo_fields[col].type )
				{
					case "upload":
					case "media":
						txt = <div className={ "d-flex " + col } style={{alignItems:"center"}}>
							<div style={{
								backgroundImage: "url(" + this.state[col] + ")",
								backgroundSize:"cover",
								width:50,
								height:50,
								margin:6
							}} />
						</div>;
						break;
					case "rgb":
						txt = <div className="d-flex" style={{alignItems:"center"}}>
							<div 
								style={{ width:14, height:14, borderRadius: 2, backgroundColor: this.state[col] }} 
								className="mr-2"
							/>
							{this.state[col]}
						</div>;
						break;
					case "date":
						txt = this.state[col] 
						?
						<Moment locale="ru" format="D MMMM YYYY">
							{new Date( this.state[col] )}
						</Moment>
						:
						__("Date not defined")
						break;
					case "geo":
						txt = <div className="small opacity_5">
							<div>{this.state[col] ? this.state[col][0] : null}</div>
							<div>{this.state[col] ? this.state[col][1] : null}</div>
						</div>; 
						break;
					case "boolean":
						txt = <div className="text-center">
							<i className={this.state[col] ? "fas fa-chevron-down text-success" : "fas fa-times text-danger"} />
						</div>; 
						break;
					case "checkbox":
						txt = Array.isArray(this.state[col]) ? this.state[col].map((e, i) =>
						{
							const elem = typeof e === "string" ? {_id:e, title:e} : e;
							return <Tag >{elem.title}</Tag>
						}) : this.state[col].toString();
						break;
					case "color":
						txt = <div style={{
							width:17,
							height:17,
							backgroundColor:this.state[col],
							border: "1px solid #00000020",
							outline: "1px solid #00000020",
							outlineOffset:2
						}} />
						break;
					case "external":
						const visibled_value = getVisibleValue(dat[3].component);
						const vv = visibled_value
							? visibled_value
							: "title";
						txt = this.state[col] ? this.state[col][vv] : null;
						break;
					case "array":
							if (dat[3].component == "string") {
								txt = null;
								if (this.state[col])
									txt = this.state[col].map((e) => <p>{e}</p>);
								break;
							}
							const visibled_value2 = getVisibleValue(dat[3].component);
							const vv2 = visibled_value2
								? visibled_value2
								: "title";
							txt = null;
							if (this.state[col]) {
								txt = this.state[col].map((e)=> <p>{e[vv2]}</p>)
							}
							break;
					default:
						txt = this.state[col];
				}
		}
		return txt ;
	}
	rows()
	{
		const { data_type } = this.props;
		let tabs = [];
		let i = 0;
		const apollo_fields = apolloFields(data_type);
		for(let e in apollo_fields)
		{
			if(!apollo_fields[e].thread) continue;
			let ttl, w;
			switch(e)
			{
				case "_id":
					ttl	= <Tooltip intent={Intent.DANGER} content={__("id")}>{__("id")}</Tooltip>;
					w 	= 30;
					break;
				default:
					ttl = __(apollo_fields[e].title);
					w 	= "auto"
			}
			tabs.push([ e, ttl, w, apollo_fields[e] ]);
		};
		
		/*
		tabs.unshift([
			'ch', 
			<label className="_check_red_ ml-2">
				<input type="checkbox" checked={this.state.allChecked} onChange={this.onAllChecked} />
			</label>, 
			50
		]);
		*/
		//					<Tooltip intent={Intent.DANGER} content={__("Add new")} key={1}>
		// 						<div className="btn btn-link text-light btn-sm" onClick={this.onEditForm}>
		// 							<Icon icon="plus" />
		// 						</div>
		// 					</Tooltip>

		//					<Tooltip intent={Intent.DANGER} content={__("Cancel")} key={1}>
		// 						<div className="btn btn-link text-light btn-sm" onClick={this.onClose}>
		// 							<Icon icon="undo" />
		// 						</div>
		// 					</Tooltip>
		tabs.unshift(['edit',
			[
				!this.state.isOpen ?
					<Button
						icon="plus"
						intent={Intent.DANGER}
						fill={true}
						onClick={this.onEditForm}
						title={__("Add new")}
						key={1}
						className="max-width-100"
					/>
					:
					<Button
						icon="undo"
						intent={Intent.DANGER}
						fill={true}
						onClick={this.onClose}
						title={__("Cancel")}
						key={1}
						className="max-width-100"
					/>,
				<span key={21}>{this.row_edit()}</span>
			], 30
		]);
		return tabs;
	}
	
	row_edit()
	{
		return <span> </span>
	}
	
	onAllChecked = evt =>
	{
		const checked = evt.currentTarget.checked ? 1 : 0;
		this.props.onAllChecked( checked );
	}
	onChecked = evt =>
	{
		const checked = evt.currentTarget.checked ? 1 : 0;
		this.props.onChecked(checked, this.props.elem._id);
	}
	onEditForm = evt =>
	{
		//const btn = evt.currentTarget
		//const par = $(btn).parents("tr").next().find(".category_card");
		//const h = $(par).height();
		//this.setState({ isOpen:true, current:tid, isNew:false, height: h + 45 });
		this.props.onOpen(this.props.i);
	}



	onClose = () =>
	{
		//this.setState({ isOpen:false, current : -1, isNew : false });
		this.props.onOpen(-1);
	}
	onChange=(field, value, id) =>
	{
		//console.log(field, value, id);
		this.setState({ field: value });
	}
}

export default compose(
	withApollo,
	withRouter
)(Row);