import React, {Component, Fragment} from "react";
import ReactDOM from 'react-dom';
import $ from "jquery";

class SCSelect extends Component
{
	constructor(props)
	{
		super(props);
		this.state = {
			label: this.getLabel( this.props.selected ),
			isOpen: this.props.isOpen,
			selected: this.props.selected,
			height: 0
		}
	}
	componentDidMount() 
	{
		document.body.addEventListener('click', this.onMouseLeaveHandler);
	}
    componentWillUnmount() 
	{
		document.body.removeEventListener('click', this.onMouseLeaveHandler);
	}
	onMouseLeaveHandler = e =>
	{	
		const domNode = ReactDOM.findDOMNode(this);
		if (!domNode || !domNode.contains(e.target))	
		{
			this.setState({
				isOpen: false, 
				height: 0
			});
		}
	}
	
	render()
	{
		const options = this.props.options || [];
		const _options = options.map((e, i)=>
		{
			const className = this.state.selected == e.value
				? 
				"sc-select-option selected" 
				: 
				"sc-select-option";
			return <div 
				className={className} 
				key={i} 
				value={e.value} 
				onClick={this.onSelect}
			>
				{ e.label }
			</div>
		});
		const className = this.state.isOpen ? "sc-select-data open" : "sc-select-data";
		return <div className="sc-select" id={this.props.id}>
			<div className="sc-select-head" onClick={this.onToggled} >
				{this.state.label}
			</div>
			<div className={className} style={{ height: this.state.height }} >
				{_options}
			</div>
		</div>
	}
	onSelect = evt =>
	{
		const selected = evt.currentTarget.getAttribute("value"); 
		this.setState({selected : selected , label: this.getLabel( selected ) }, () =>
		{
			console.log(this.state.selected);
			this.props.onChange(this.state.selected);
		});
		
	}
	onToggled = evt =>
	{
		this.setState({isOpen : !this.state.isOpen, height : this.getHeight() });
	}
	getHeight = () =>
	{
		if( !this.state.isOpen )
		{
			let total = 2;
			$("#"+this.props.id + " .sc-select-data")
				.children()
					.each((i, e) =>
					{
						total += $(e).outerHeight();
					});
			return total;
		}
		else
		{
			return 0;
		}
	}
	getLabel(selected)
	{
		console.log( selected );
		return !selected
			? 
			this.props.placeholder 
			: 
			this.props.options.filter(e => e.value == selected)[0].label;
	}
}
export default SCSelect;