import React, {Component} from "react";
import {menu} from "../../../layouts/routing";
import LayoutIcon from "../../../layouts/LayoutIcon";
import { NavLink } from 'react-router-dom';
import {__} from "../../../layouts/utilities/i18n";
import {isCapability} from "../../../layouts/user";
const injectTapEventPlugin = require("react-tap-event-plugin");

class PictogrammMenu extends Component
{
	state = {
		current:this.props.current,
		hover:false
	}
	componentWillReceiveProps (nextProps )
	{
		this.setState({
			current: nextProps.current
		});
	}
	render()
	{
		const {children, route, icon, title, capability} = this.props;
		// const isRole = isCapability( capability);
		// if(isRole) return "";
		const arrow = children && children.length > 0 
			?
			<span className="arrow fas fa-caret-right">
			</span>
			:
			null
		return <NavLink
			to={"/" + route}
			className={"layout-left-btn " + ( this.propsi == this.state.current ? " active" : "")} 
			activeClassName="active"
			i={this.props.current} 
		>
			<div className={"layout-menu-icon"} 
				onMouseEnter={() => this.toggleHover()} 
				onMouseLeave={() => this.toggleHover()}		
			>
				<LayoutIcon
					src={ icon }
					className="left-menu-icon"
				/>
			</div>
			<div className={ "layout-menu-left-label " +( this.state.hover ? "hover" : null ) }>
				{__(title)}
			</div>
			{arrow}
		</NavLink>
	}
	
	toggleHover()
	{
		this.setState({hover: !this.state.hover})
	}
}
export default PictogrammMenu;