import layouts from "./layouts";
import React from "react";
import {isCapability} from "./user";

export function concatRouting()
{
    let routing = [];

    for (let key in layouts.routing) {
        routing = routing.concat(layouts.routing[key]);
    }

    return routing;
}

export function routeData(e=undefined,
                          child=undefined,
                          grandchild=undefined,
                          forceRoute=undefined)
{
    let preroute, route, routeBasic, noexact_route, currentE, capability;
    if(grandchild)
    {
        // console.log("grandchild");
        // console.log(grandchild);
        capability = grandchild.capability;
        preroute 	  = '/' + e.route +  '/' + child.route;
        route		  = typeof forceRoute !== "undefined" ? forceRoute : grandchild.route + "";
        noexact_route = typeof forceRoute !== "undefined" ? forceRoute : grandchild.route + "/:id";
        currentE = grandchild;
    }
    else if(child)
    {
        // console.log("child");
        // console.log(child);
        capability = child.capability;
        preroute 	= '/' + e.route;
        route		= typeof forceRoute !== "undefined" ? forceRoute : child.route;
        noexact_route = forceRoute ? forceRoute : child.route + "/:id";
        currentE = child;
    }
    else
    {
        // console.log("e");
        // console.log(e);

        capability = e.capability;
        preroute 	= "";
        route		= typeof forceRoute !== "undefined" ? forceRoute : e.route;
        noexact_route = forceRoute ? forceRoute : e.route + "/:id";
        currentE = e;
    }

    return {currentE: currentE, preroute: preroute, route: route, noexact_route: noexact_route, capability: capability};
}

export function existRouting(key = "") {
    const routingArray = layouts.routing[key];

    return routingArray && routingArray.length > 0 ;
}

export function existRoutingChilder(key = "") {
    const routingArray = layouts.routing[key][0];

    return routingArray.children && routingArray.children.length > 0;
}

export function getFirstRoute(key = "") {
    return layouts.routing[key][0];
}

export function mainPage() {
    let main = layouts.routing.extended_routes.filter(e => e.route === "")[0];
    if(!main)
    {
        main = layouts.routing.menu[0];
    }
    return main;
}


export function mainMenu(){
    return layouts.routing.main_menu;
}

export function menu(){
    return layouts.routing.menu;
}

export function footer(){
    return layouts.routing.footer;
}

export function profile() {
    return layouts.routing.profile;
}

export function link() {
    return layouts.routing.link;
}

