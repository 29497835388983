import React, {Component, Fragment} from "react";
import BasicState from "../layouts/BasicState";
import { NavLink, Link } from 'react-router-dom';
import {__} from "../layouts/utilities/i18n";

class NoMatchState extends BasicState
{
	myState = () =>
	{
		return <div className="row text-center">
			<div className="col-12 my-4">
				<div  className="_404" />
			</div>
			<div className="col-12 lead">
				{__("this page no searched")}
			</div>
			<div className="col-12 my-4">
				<Link 
					className="btn btn-danger btn-sm"
					to="/"
				>
					{__("Return to main page")}
				</Link>
			</div>
		</div>
	}
	getRoute = () =>
	{
		return "404";
	}
}

//		if (this.props.data.loading) return <Loading/>;
// 		console.log( this.props.data.init );
// 		return <div className="row text-center">
// 			<div className="col-12">
// 				<img src={ this.props.data.init.presets['404'] } alt="404" style={{ maxWidth:220, marginBottom:30 }}/>
// 			</div>
// 			<div className="col-12 display-3 text-light">
// 				404
// 			</div>
// 			<div className="col-12 lead text-light mb-5">
// 				{i18n.t("Uncorrect URL-adress")}
// 			</div>
// 		</div>;


export default NoMatchState;