import React, {Fragment} from 'react'
import {NavLink} from 'react-router-dom'

class SCSchoolForm extends React.Component 
{
	state = {}
	componentDidMount ()	
	{
		this.updateWindowDimensions();
		window.addEventListener('resize', this.updateWindowDimensions);
	}
	componentWillUnmount() 
	{
		window.removeEventListener('resize', this.updateWindowDimensions);
	}
	updateWindowDimensions = () =>
	{
		
		this.setState({ 
			width : document.body.clientWidth, 
			height: document.body.clientHeight			
		});

	}
	mobile()
	{
		return <div className="btn-add">
				<NavLink to="/new" className="addschoolbtn btn btn-xl " type="button">
					<img className="icon-plus" src="/assets/img/icon-plus.svg"/>
				</NavLink>
			</div>
	}
	screen()
	{
		return <Fragment>	
			<div className="col col-xl-3 bg-tag-blue mr-auto d-flex justify-content-start justify-content-xl-around px-2 py-2">
				<a href="https://vk.com/alternativnoeobrazovanie" target="_blank">
					<i className="d-flex fab fa-vk px-2"/>
				</a>
				<a href="https://www.facebook.com/groups/alternativnoeobr/" target="_blank">
					<i className="d-flex fab fa-facebook-square px-2"/>
				</a>
				<a href="https://www.instagram.com/so_alternativa/" target="_blank">
					<i className="d-flex fab fa-instagram px-2" />
				</a>
				<a href="https://www.youtube.com/channel/UC6mYDxAi3caYNyJ58awBqKA" target="_blank">
					<i className="d-flex fab fa-youtube px-2" />
				</a>
			</div>

			<div className="btn-add">
				<NavLink to="/new" className="addschoolbtn btn btn-xl d-flex align-items-center" type="button">
					<img className="icon-plus mr-2" src="/assets/img/icon-plus.svg"/>
					<span className="font-weight-bold">Добавить в каталог</span>
				</NavLink>
			</div>
		</Fragment>
	}
	render ()
	{ 
		return this.state.width > 900 ? this.screen() : this.mobile();
	}
}

export default SCSchoolForm