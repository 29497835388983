import React, {Component, Fragment} from "react";
import {__} from "../../layouts/utilities/i18n";
import {getChangeName, getInputTypeName, getMutationArgs, mutationEdit} from "../../layouts/schema";
import {getInput} from "../../layouts/schema/ecosystem";
import {Mutation} from "react-apollo";
import {AppToaster} from "../../layouts/utilities/blueUtils";
import {Intent} from "@blueprintjs/core";

class ContactForm extends Component
{
	constructor(props)
	{
		super(props);
		this.state = {
			name:"",
			title:"",
			email:"",
			content:""
		};
	}

	contactForm = (m_change) =>{

		return <form className="question_form w-100" onSubmit={(evt)=>this.onSubmit(evt, m_change)}>
			<div className={"row " + this.props.formClass} >
				<div className="col-12">
					<div className="py-3">
						<h1 className="text-center">
							{this.props.title}
						</h1>
					</div>
				</div>
				<div className="col-12">
					<input
						type="text"
						className="form-control"
						placeholder="Имя"
						value={this.state.name}
						onChange={this.onName}
					/>
					<input
						type="email"
						className="form-control"
						placeholder="E-mail"
						value={this.state.email}
						onChange={this.onEmail}
					/>
					<input
						type="text"
						className="form-control"
						placeholder="Тема"
						value={this.state.title}
						onChange={this.onTitle}
					/>
				</div>
				<div className="col-12 py-3">
					<textarea
						className="form-control"
						rows="6"
						placeholder="Задать вопрос..."
						value={this.state.content}
						onChange={this.onContent}
					/>
				</div>
				<div className="col-12 justify-content-center d-flex">
					<input
						type="submit"
						className="btn btn-primary addschoolbtn"
						value="Отправить"
					/>
				</div>
			</div>
		</form>
	}

	render()
	{

		const mutation_name = getChangeName( "ContactForm" );
		const input_type_name = getInputTypeName( "ContactForm" );
		const mutation_args = getMutationArgs( "ContactForm" );
		const change_mutation = mutationEdit( "ContactForm", mutation_name, input_type_name, mutation_args );


		return <Mutation mutation={change_mutation}>
			{( m_change, { data } ) => this.contactForm(m_change)}
		</Mutation>


	}
	onName = evt =>
	{
		evt.preventDefault();
		this.setState({name:evt.currentTarget.value});
	}
	onEmail = evt =>
	{
		evt.preventDefault();
		this.setState({email:evt.currentTarget.value});
	}
	onTitle = evt =>
	{
		evt.preventDefault();
		this.setState({title:evt.currentTarget.value});
	}
	onContent = evt =>
	{
		evt.preventDefault();
		this.setState({content:evt.currentTarget.value});
	}
	
	onSubmit = (evt, m_change) =>
	{
		evt.preventDefault();
		let input = getInput(this.state, "ContactForm");

		if(false){
			AppToaster.show({
				intent: Intent.DANGER,
				icon: "tick",
				message: __("Заполните все поля.")
			});
			return;
		}

		m_change({
			variables:
				{
					"input": input
				},
			update: (store, { data: data }) =>
			{
				console.log(data);
				const respData = data["changeContactForm"];
				if(respData)
				{
					AppToaster.show({
						intent: Intent.SUCCESS,
						icon: "tick",
						message: __("Ваша заявка принята")
					});
				}else{
					AppToaster.show({
						intent: Intent.DANGER,
						icon: "tick",
						message: __("Что-то пошло не так.")
					});
				}
			}
		});

	}
}
export default ContactForm;